import 'isomorphic-fetch';

// Cockpit (https://getcockpit.com) is hosted as a Docker container on a DigitalOcean CentOS droplet
// The droplet right now is hosted under a DO account under 'jbryan@uxologygroup.com'
// Since it is docker, the host droplet also has a public IP of 104.248.190.28
// firewall-cmd restricts external access on the droplet to all ports except ssh and 8080
// Cockpit is running default config, no changes made to Docker config, all config of Cockpit done thru web UI
// NOTE: The name 'uxgweb-cockpit.jbiconsult.com' is used instead of the IP for the server so that
// we can use nginx and let's encrypt to wrap the docker connection in HTTPS.
// We have to use HTTPS because the production website is served over HTTPS, and API calls will fail if non-https in prod
const SERVER = 'https://uxgweb-cockpit.jbiconsult.com';
const TOKEN = 'account-6a6793f77c91408c796750a17dfc77';
const UPLOADS_ROOT = '/storage/uploads';
const IMAGE_API = `${SERVER}/api/cockpit/image`;
const IMAGE_ROOT = `${SERVER}${UPLOADS_ROOT}`;
const BLOG_API = `${SERVER}/api/collections/get/blogposts?token=${TOKEN}`;

function normalizeEntries(res) {
	if (!res || !res.entries) return res;

	// The Docker image that Cockpit is on doesn't seem to have time correct.
	// This magic epoch corrects the difference between the "right" time and whatever time Cockpit is using
	// Found it by updating post in Cockpit, getting the _modified timestamp from the API,
	// then using now=new Date().getTime() and subtracting the _modified from now
	const magic = 1543986920895;

	// Max image width - use Cockpit to constrain images to this size to prevent really large hits on clients
	const width = 2048;
	const quality = 85; // constrain quality for same reason

	res.entries.map((entry) => {
		if (entry.image) {
			// Additional documentation on this API at https://discourse.getcockpit.com/t/how-to-get-different-images-sizes-for-multiple-images/164/5
			// and https://getcockpit.com/documentation/api/cockpit
			entry.image.fixed = `${IMAGE_API}?token=${TOKEN}&src=${UPLOADS_ROOT}${entry.image.path}&w=${width}&q=${quality}&o=1`;

			// Make path absolute for original image
			entry.image.path = IMAGE_ROOT + entry.image.path;
		}

		['_modified', '_created'].forEach((_) => (entry[_] += magic));
	});

	return res;
}

function getPosts(paging) {
	paging = Object.assign(
		{
			pageSize: 10,
			page: 10,
		},
		paging || {},
	);
	if (!paging.pageSize) paging.pageSize = 10;
	if (!paging.page) paging.page = 0;

	// // Pages are 0 based
	// paging.page --;

	return fetch(BLOG_API, {
		method: 'post',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({
			filter: { published: true },
			limit: paging.pageSize,
			skip: paging.page * paging.pageSize,
			sort: { _created: -1 },
			populate: 1, // resolve linked collection items
		}),
	})
		.then((res) => res.json())
		.then((res) => normalizeEntries(res))
		.then((res) => {
			// console.log("[getPosts]", res);
			return res;
		});
}

function getPostBySlug(slug) {
	if (!slug) return Promise.resolve(null);

	return fetch(BLOG_API, {
		method: 'post',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({
			filter: {
				published: true,
				title_slug: slug,
			},
			limit: 1,
			skip: 0,
			sort: { _created: -1 },
			populate: 1, // resolve linked collection items
		}),
	})
		.then((res) => res.json())
		.then((res) => normalizeEntries(res))
		.then((res) => {
			// console.log("[getPostBySlug]", slug, res);
			return res && res.entries ? res.entries[0] : null;
		});
}

export { getPosts, getPostBySlug };
