import React from 'react';
import { Router, Link } from '@reach/router';
import BlogHome from '../components/blog-home';
import BlogPost from '../components/blog-post';

export default class BlogAppPage extends React.Component {
	render() {
		return (
			<Router>
				<BlogHome path="/blog/" />
				<BlogHome path="/blog/p/:page" />
				<BlogPost path="/blog/post/:slug" />
			</Router>
		);
	}
}
