import React from 'react';
import $ from 'jquery';
import './anim-headline.scss';

export default class AnimHeadline extends React.Component {
	componentDidMount() {
		// console.log("[AnimHeadline] props=", this.props);
		this.$el = $(this.el);
		this._timers = [];
		this.$mountedElement = this.headlineElement$();
		this.$el.append(this.$mountedElement);
		this._later(() => this.startRotating(), 10);
	}

	componentWillUnmount() {
		this.stopActiveTimers();
	}

	render() {
		return <div ref={(el) => (this.el = el)} />;
	}

	headlineElement$() {
		const words = (this.props.words || [])
			.map(
				(word, idx) =>
					`<b class="${idx === 0 ? 'is-visible' : ''}">${word}</b>`,
			)
			.join('\n');

		return $(`
			<div class='anim-headline'>
				<h2 class="cd-headline letters rotate-2 ">
					<span class="cd-words-wrapper">
						${words}
					</span>
				</h2>
			</div>
		`);
	}

	// Use _later() in the external code added in startRotating() instead of setTimeout
	// so we can keep track of what timers are active, so that when we hit
	// componentWillUnmount(), we can stop any active timers.
	_later(fn, time) {
		let id;
		id = window.setTimeout(() => {
			this._timers = this._timers.filter((_) => _ !== id);
			fn();
		}, time);
		this._timers.push(id);
	}

	stopActiveTimers() {
		this._timers.forEach((id) => clearTimeout(id));
	}

	startRotating() {
		// set animation timing
		let animationDelay = 2500;
		// loading bar effect
		let barAnimationDelay = 3800;
		let barWaiting = barAnimationDelay - 3000; // 3000 is the duration of the transition on the loading bar - set in the scss/css file
		// letters effect
		let lettersDelay = 50;
		// type effect
		let typeLettersDelay = 150;
		let selectionDuration = 500;
		let typeAnimationDelay = selectionDuration + 800;
		// clip effect
		let revealDuration = 600;
		let revealAnimationDelay = 1500;
		// NB: Override for code below to localize to our element
		// $ = selector => this.$mountedElement.find(selector),
		let _this = this;
		let i;

		initHeadline();

		const words = this.props.words || [];

		function initHeadline() {
			// insert <i> element for each letter of a changing word
			singleLetters(
				_this.$mountedElement.find('.cd-headline.letters').find('b'),
			);
			animateHeadline(_this.$mountedElement.find('.cd-headline'));
		}

		function singleLetters($words) {
			// console.log("[singleLetters] $words=", $words);
			$words.each(function () {
				let word = $(this);
				let letters = word.text().split('');
				let selected = word.hasClass('is-visible');
				for (i in letters) {
					if (word.parents('.rotate-2').length > 0)
						letters[i] = `<em>${letters[i]}</em>`;
					letters[i] = selected
						? `<i class="in">${letters[i]}</i>`
						: `<i>${letters[i]}</i>`;
				}
				let newLetters = letters.join('');
				word.html(newLetters).css('opacity', 1);
			});
		}

		function animateHeadline($headlines) {
			let duration = animationDelay;
			$headlines.each(function () {
				let headline = $(this);

				if (headline.hasClass('loading-bar')) {
					duration = barAnimationDelay;
					_this._later(function () {
						headline.find('.cd-words-wrapper').addClass('is-loading');
					}, barWaiting);
				} else if (headline.hasClass('clip')) {
					let spanWrapper = headline.find('.cd-words-wrapper');
					let newWidth = spanWrapper.width() + 10;
					spanWrapper.css('width', newWidth);
				} else if (!headline.hasClass('type')) {
					// assign to .cd-words-wrapper the width of its longest word
					let words = headline.find('.cd-words-wrapper b');
					let width = 0;
					words.each(function () {
						let wordWidth = $(this).width();
						if (wordWidth > width) width = wordWidth;
					});
					headline.find('.cd-words-wrapper').css('width', width);
				}

				// trigger animation
				_this._later(function () {
					hideWord(headline.find('.is-visible').eq(0));
				}, duration);
			});
		}

		function hideWord($word) {
			let nextWord = takeNext($word);

			if ($word.parents('.cd-headline').hasClass('type')) {
				let parentSpan = $word.parent('.cd-words-wrapper');
				parentSpan.addClass('selected').removeClass('waiting');
				_this._later(function () {
					parentSpan.removeClass('selected');
					$word
						.removeClass('is-visible')
						.addClass('is-hidden')
						.children('i')
						.removeClass('in')
						.addClass('out');
				}, selectionDuration);
				_this._later(function () {
					showWord(nextWord, typeLettersDelay);
				}, typeAnimationDelay);
			} else if ($word.parents('.cd-headline').hasClass('letters')) {
				let bool = $word.children('i').length >= nextWord.children('i').length;
				hideLetter($word.find('i').eq(0), $word, bool, lettersDelay);
				showLetter(nextWord.find('i').eq(0), nextWord, bool, lettersDelay);
			} else if ($word.parents('.cd-headline').hasClass('clip')) {
				$word
					.parents('.cd-words-wrapper')
					.animate({ width: '2px' }, revealDuration, function () {
						switchWord($word, nextWord);
						showWord(nextWord);
					});
			} else if ($word.parents('.cd-headline').hasClass('loading-bar')) {
				$word.parents('.cd-words-wrapper').removeClass('is-loading');
				switchWord($word, nextWord);
				_this._later(function () {
					hideWord(nextWord);
				}, barAnimationDelay);
				_this._later(function () {
					$word.parents('.cd-words-wrapper').addClass('is-loading');
				}, barWaiting);
			} else {
				switchWord($word, nextWord);
				_this._later(function () {
					hideWord(nextWord);
				}, animationDelay);
			}
		}

		function showWord($word, $duration) {
			if ($word.parents('.cd-headline').hasClass('type')) {
				showLetter($word.find('i').eq(0), $word, false, $duration);
				$word.addClass('is-visible').removeClass('is-hidden');
			} else if ($word.parents('.cd-headline').hasClass('clip')) {
				$word
					.parents('.cd-words-wrapper')
					.animate({ width: $word.width() + 10 }, revealDuration, function () {
						_this._later(function () {
							hideWord($word);
						}, revealAnimationDelay);
					});
			}
		}

		function hideLetter($letter, $word, $bool, $duration) {
			$letter.removeClass('in').addClass('out');

			if (!$letter.is(':last-child')) {
				_this._later(function () {
					hideLetter($letter.next(), $word, $bool, $duration);
				}, $duration);
			} else if ($bool) {
				_this._later(function () {
					hideWord(takeNext($word));
				}, animationDelay);
			}

			if (
				$letter.is(':last-child') &&
				$('html').hasClass('no-csstransitions') // spell-checker:disable-line
			) {
				let nextWord = takeNext($word);
				switchWord($word, nextWord);
			}
		}

		function showLetter($letter, $word, $bool, $duration) {
			$letter.addClass('in').removeClass('out');

			if (!$letter.is(':last-child')) {
				_this._later(function () {
					showLetter($letter.next(), $word, $bool, $duration);
				}, $duration);
			} else {
				if ($word.parents('.cd-headline').hasClass('type')) {
					_this._later(function () {
						$word.parents('.cd-words-wrapper').addClass('waiting');
					}, 200);
				}
				if (!$bool) {
					_this._later(function () {
						hideWord($word);
					}, animationDelay);
				}
			}
		}

		function takeNext($word) {
			return !$word.is(':last-child')
				? $word.next()
				: $word.parent().children().eq(0);
		}

		function takePrev($word) {
			return !$word.is(':first-child')
				? $word.prev()
				: $word.parent().children().last();
		}

		function switchWord($oldWord, $newWord) {
			$oldWord.removeClass('is-visible').addClass('is-hidden');
			$newWord.removeClass('is-hidden').addClass('is-visible');
		}
	}
}
