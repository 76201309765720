import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as styles from './loading-spinner.module.scss';

const LoadingSpinner = ({ title }) => (
	<div className={styles.loading}>
		<h1>
			<FontAwesomeIcon icon="spinner" size="lg" spin />
			<i>{title || 'Loading...'}</i>
		</h1>
	</div>
);

export default LoadingSpinner;
