import React, { Component } from 'react';
import Moment from 'react-moment';

import { Link } from '@reach/router';
import ContactWidget from './contact-widget';
import Footer from './footer';
import Layout, { PageColors } from './layout';
import LoadingSpinner from './loading-spinner';
import AnimHeadline from './anim-headline';
import * as styles from './blog-home.module.scss';

import { getPosts } from '../utils/cockpit';

export default class BlogHome extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loaded: false,
		};
	}

	fetchPosts(page) {
		getPosts({ page, pageSize: 10 }).then((resp) => {
			if (!this.mounted) return;

			this.setState({
				loaded: true,
				resp,
			});
		});
	}

	componentWillMount() {
		let page = this.props.page || 0;
		this.mounted = true;

		this.fetchPosts(page);
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ loaded: false });

		let page = nextProps.page || 0;

		this.fetchPosts(page);
	}

	render() {
		const hero = (
			<section className={styles.hero} style={{ background: PageColors.blog }}>
				<div className="content">
					<AnimHeadline words={['UX&nbsp;MUSINGS', 'BEST&nbsp;PRACTICES']} />
					<h1 className="hs-text-3">
						<p style={{ lineHeight: '175%' }}>
							<b>
								HERE, YOU'LL FIND A BLOG OF VARIOUS UX MUSINGS AND BEST
								PRACTICES.
								<br />
							</b>
						</p>
					</h1>
				</div>
			</section>
		);

		if (this.state.loaded) {
			const { next_page, previous_page } = this.state.resp;
			// console.log("posts:", this.state.resp.data);

			const summarize = (text) => {
				const max = 128;
				// Remove <tags> and &entities;
				text = text.replace(/(<[^\>]+>|\&[^\s\;]+;)/g, '');
				// Ellide if needed
				if (text.length > max) {
					return `${text.substring(0, max)}…`;
				}

				return text;
			};

			return (
				<Layout page="blog" location={this.props.location}>
					{hero}
					<section className={styles.blog}>
						<div className={styles.blogHome}>
							<h1>Our Blog</h1>

							{this.state.resp.entries.map((post) => {
								return (
									<div key={post.title_slug} className={styles.blogItem}>
										<Link to={`/blog/post/${post.title_slug}`}>
											{post.image && <img src={post.image.fixed} />}
											<h2>{post.title}</h2>
											<Moment format="YYYY/MM/DD">{post._created}</Moment>
											<p className={styles.summary}>
												{summarize(post.content)}
											</p>
										</Link>
									</div>
								);
							})}

							<br />

							<div>
								{previous_page && (
									<Link to={`/blog/p/${previous_page}`}>Prev</Link>
								)}

								{next_page && <Link to={`/blog/p/${next_page}`}>Next</Link>}
							</div>
						</div>
					</section>

					<ContactWidget />
					<Footer page="blog" location={this.props.location} />
				</Layout>
			);
		}
		return (
			<Layout page="blog" location={this.props.location}>
				{hero}
				<LoadingSpinner title="Loading latest blog posts..." />
			</Layout>
		);
	}
}
