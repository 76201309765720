import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Layout, { PageColors } from './layout';
import LoadingSpinner from './loading-spinner';
import ContactWidget from './contact-widget';
import Footer from './footer';

import * as styles from './blog-post.module.scss';

import { getPostBySlug } from '../utils/cockpit';

class BlogPost extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loaded: false,
		};

		this.mounted = false;
	}

	componentWillMount() {
		this.mounted = true;
		let { slug } = this.props;

		getPostBySlug(slug).then((resp) => {
			if (!this.mounted) return;

			this.setState({
				loaded: true,
				post: resp,
			});
		});
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	render() {
		if (this.state.loaded) {
			const { post } = this.state;
			if (!post || !post.content) {
				return (
					<Layout page="blog" location={this.props.location}>
						<div className={styles.postContainer}>
							<Helmet>
								<title>Invalid Post</title>
							</Helmet>
							<h1>Invalid Post URL</h1>
						</div>
						<ContactWidget />
						<Footer />
					</Layout>
				);
			}
			// console.log("[blog-post] rendering post:", post);

			// var parsed = reader.parse(post.content); // parsed is a 'Node' tree
			// // transform parsed if you like...
			// var html = writer.render(parsed); // result is a String
			const html = post.content;

			return (
				<Layout page="blog" location={this.props.location}>
					<div className={styles.postContainer}>
						<Helmet>
							<title>{post.title}</title>
							<meta name="description" content={post.content} />
							<meta name="og:image" content={post.image && post.image.fixed} />
						</Helmet>
						{post.image && (
							<div className={styles.featuredImgWrap}>
								<img src={post.image.fixed} />
							</div>
						)}
						<h1>{post.title}</h1>
						<div
							className={styles.contentWrap}
							dangerouslySetInnerHTML={{ __html: html }}
						/>
					</div>
					<div style={{ height: '5rem' }}>{/* spacing */}</div>
					<ContactWidget />
					<Footer page="blog" location={this.props.location} />
				</Layout>
			);
		}
		return (
			<Layout page="blog" location={this.props.location}>
				<LoadingSpinner title="Loading blog post..." />
			</Layout>
		);
	}
}

export default BlogPost;
